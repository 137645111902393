<template>
    <!-- Header Section Begin -->
    <header class="header-section">
        <!-- Topbar -->
        <div class="header-top">
            <div class="container">
                <div class="row">
                    <div class="col-10 col-lg-4 hidden-sm-md">
                        <div class="topbar-item text-xl-center">
                           <a :href="Link1" target="_blank" style="color: #252525;font-size:12px;">{{ Fone1 }}</a>
                        </div>
                    </div>
                    <div class="col-lg col-lg-4 hidden-mobile">
                        <div class="topbar-item text-xl-center">
                            <a :href="Link2" target="_blank" style="color: #252525;font-size:12px;">{{ Fone2 }}</a>
                        </div>
                    </div>
                    <!-- Somente este contato no celular -->
                    <div class="col-10 col-lg-4 col-md-11 col-lg">
                        <div class="topbar-item text-xl-center">
                            {{ Fone3 }}
                        </div>
                    </div>
                    <!--  -->
                    <!--<div class="col-2 col-lg-2">
                        <div class="topbar-social">
                            <a v-if="Face[0].visivel" :href="Face[0].nome" target="_blank">
                                <i class="fab fa-facebook-square fa-2x topbar-social"></i>
                            </a>
                            <a v-if="Yout[0].visivel" :href="Yout[0].nome" target="_blank">
                                <i class="fab fa-youtube-square fa-2x topbar-social"></i>
                            </a>
                            <a v-if="Insta[0].visivel" :href="Insta[0].nome" target="_blank">
                                <i class="fab fa-instagram fa-2x topbar-social"></i>
                            </a>
                            <a v-if="Lkdin[0].visivel" :href="Lkdin[0].nome" target="_blank">
                                <i class="fab fa-linkedin fa-2x topbar-social"></i>
                            </a>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>

        <!-- Logo + Search + Meu orçamento -->
        <div class="container">
            <div class="inner-header">
                <div class="row">
                    <!-- Logo -->
                    <div class="col-md-3 col-lg-9">
                        <div class="logo">
                            <a href="" @click="$redirect_reload('/')">
                                <img src="/img/logo.jpg" alt="" style="width: 100%;" />
                            </a>
                        </div>
                    </div>
                    <!-- Search -->
                    <!--div class="col-md-7 col-lg-4 offset-lg-1 d-flex align-items-center hidden-sm-md" style="display: none;">
                        <div class="advanced-search">
                            <div class="input-group input-search">
                                <input type="search" v-model="textobusca" @keyup.enter="ValidaBusca()" required="required" class="header_search_input" placeholder="Pesquise aqui">
                                <button @click.prevent="ValidaBusca()"  class=""><i class="fas fa-search"></i></button>
                            </div>
                        </div>
                    </div-->
                    <!-- Meu Orçamento -->
                    <div class=" col-md-2 col-lg-3 text-center hidden-sm">
                        <div class="row">
                            <div class="col-12" style="display: inline-flex;padding-bottom: 10px;padding-top: 30px;">
                                <div v-if="usuario == ''" class="col topbar-content separador">
                                    <router-link to="/login" style="color:#000000;font-size:14px;">Login</router-link>
                                </div>
                                <div v-else class="col topbar-content separador" style="font-size:14px;">
                                    Olá, {{ usuario.nomeEmpresa }}
                                </div>
                                <div v-if="usuario != ''" class="col topbar-content" style="font-size:14px;">
                                    <a href="" @click="Logout()" style="color:#000000;">Logout</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                             <div class="col-12">
                                 <router-link to="carrinho" class="link-principal">
                                    <i class="fas fa-clipboard-list icon-orcamento m-right-10"></i>Meu Orçamento
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Categorias + Menu -->
        <div class="nav-item">
            <div class="container">
                <div class="nav-depart">
                    <!-- Submenu (Categorias) -->
                    <div class="depart-btn">
                        <i class="fas fa-bars"></i>
                        <span>Categorias</span>
                        <ul class="depart-hover">
                            <li v-for="segmento in Segmentos" :key="segmento.segmentoProdNivel1Id">
                                <a href="#" @click="$redirect_reload('/produtos/' + segmento.segmentoProdNivel1Id + '/' + '0' + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))">{{ segmento.nomeSegmento}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Menu desktop -->
                <nav class="nav-menu mobile-menu">
                    <ul>
                        <li><a href="#" @click="$redirect_reload('/')">Home</a></li>
                        <li><a href="#" @click="$redirect_reload('/sobre')">A Empresa</a></li>
                        <li><a href="#" @click="$redirect_reload('/produtos')">Produtos</a></li>
                        <li><a href="#" @click="$redirect_reload('/videos')">Vídeos</a></li>
                        <li><a href="#" @click="$redirect_reload('/blog')">Blog</a></li>
                        <li><a href="#" @click="$redirect_reload('/contato')">Contato</a></li>
                    </ul>
                </nav>
                <!-- <div id="mobile-menu-wrap"></div> -->
                <!-- Menu Mobile -->
                 <div v-if="usuario != ''" class="col-12 hidden-md hidden-lg hidden-xl mt-3" style="display: inline-flex;">
                    <div class="col topbar-content separador" style="font-size:14px;">
                        Olá, {{ usuario.nomeEmpresa }}
                    </div>
                    <div v-if="usuario != ''" class="col topbar-content" style="font-size:14px;">
                        <a href="" @click="Logout()" style="color:#000000;">Logout</a>
                    </div>
                </div>
                <b-navbar-toggle target="nav-collapse" class="hidden-md hidden-lg hidden-xl mt-4 mb-2"><i class="fas fa-bars"></i> MENU</b-navbar-toggle>
               
                <b-collapse id="nav-collapse" class="mb-4" is-nav>
                    <b-navbar-nav class="navbar-nav mobile-menu">
                        <b-nav-item>
                            <li class="nav-item nav-item-mobile">
                                <a href="#" @click="$redirect_reload('/')" class="nav-link" style="padding: 0.1rem 0.1rem;">Home</a>
                            </li>
                        </b-nav-item>
                        <b-nav-item>
                            <li class="nav-item nav-item-mobile">
                                <a href="#" @click="$redirect_reload('/sobre')" class="nav-link" style="padding: 0.1rem 0.1rem;">A Empresa</a>
                            </li>
                        </b-nav-item>
                         <b-nav-item>
                            <li class="nav-item nav-item-mobile">
                                <a href="#" @click="$redirect_reload('/produtos')" class="nav-link" style="padding: 0.1rem 0.1rem;">Produtos</a>
                            </li>
                        </b-nav-item>
                        <b-nav-item>
                            <li class="nav-item nav-item-mobile">
                                <a href="#" @click="$redirect_reload('/videos')" class="nav-link" style="padding: 0.1rem 0.1rem;">Vídeos</a>
                            </li>
                        </b-nav-item>
                        <b-nav-item>
                            <li class="nav-item nav-item-mobile">
                                <a href="#" @click="$redirect_reload('/blog')" class="nav-link" style="padding: 0.1rem 0.1rem;">Blog</a>
                            </li>
                        </b-nav-item>
                        <b-nav-item>
                            <li class="nav-item nav-item-mobile">
                                <a href="#" @click="$redirect_reload('/contato')" class="nav-link" style="padding: 0.1rem 0.1rem;">Contato</a>
                            </li>
                        </b-nav-item>
                        <b-nav-item>
                             <li class="nav-item nav-item-mobile">
                             <a href="#" @click="$redirect_reload('/login')" class="nav-link" style="padding: 0.1rem 0.1rem;">Login</a>
                             </li>
                        </b-nav-item>
                          <b-nav-item>
                               <li class="nav-item nav-item-mobile">
                                  <a href="#" @click="$redirect_reload('/meusDados')" class="nav-link" style="padding: 0.1rem 0.1rem;">Meus Dados</a>
                               </li>
                        </b-nav-item>
                          <b-nav-item>
                              <li class="nav-item nav-item-mobile">
                                 <a href="#" @click="$redirect_reload('/meusPedidos')" class="nav-link" style="padding: 0.1rem 0.1rem;">Meus Pedidos</a>
                              </li>
                        </b-nav-item>
                        <div class="meu_orcamento">
                            <b-nav-item>
                                <li class="nav-item nav-item-mobile">
                                    <a href="" @click="$redirect_reload('/carrinho')" class="nav-link">
                                        <i class="fas fa-clipboard-list icon-orcamento m-right-10"></i>
                                        Meu Orçamento
                                    </a>
                                </li>
                            </b-nav-item>
                        </div>
                    </b-navbar-nav>
                </b-collapse>
                <!--  -->
            </div>
        </div>
    </header>
    <!-- Header End -->
    <!-- <div>
      <div class="container container-desktop">
        <b-navbar toggleable="lg" class="ftco_navbar ftco-navbar-light">
        <b-navbar-brand>
          <router-link to="/" class="navbar-brand">
            <img class="logo-topo" src="/img/01.png" alt="Logo">
          </router-link>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="navbar-nav menu-xl ml-auto">
            <b-nav-item><li class="nav-item"><router-link exact to="/" class="nav-link">Home</router-link></li></b-nav-item>
            <b-nav-item><li class="nav-item"><router-link to="/sobre" class="nav-link">Sobre</router-link></li></b-nav-item>
            <b-nav-item><li class="nav-item"><router-link to="/servicos" class="nav-link">Serviços</router-link></li></b-nav-item>
            <b-nav-item><li class="nav-item"><router-link to="/blog" class="nav-link">Blog</router-link></li></b-nav-item>
            <b-nav-item><li class="nav-item"><router-link to="/contato" class="nav-link">Contato</router-link></li></b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </b-navbar>
      </div>
    </div> -->
</template>
<script>
    //var coll = document.getElementsByClassName("collapsible");
    //var i;

    //for (i = 0; i < coll.length; i++) {
    //    coll[i].addEventListener("click", function () {
    //        this.classList.toggle("active");
    //        var content = this.nextElementSibling;
    //        if (content.style.display === "block") {
    //            content.style.display = "none";
    //        } else {
    //            content.style.display = "block";
    //        }
    //    });
    //}


    export default {
        data() {
            return {
                Fone1: '',
                Link1:'',
                Fone2: '',
                Link2:'',
                Fone3: '',
                ListaCabecalho: [],
                Face: [],
                Insta: [],
                Yout: [],
                Lkdin: [],
                Segmentos: [],
                segmento: {},
                textobusca: '',
                usuario: {},
            };
        },
        props: {
            SegmentoId: {
                type: Number,
                required: true,
                default: 0
            }
        },
        methods: {
            ValidaBusca() {
                if (this.textobusca != '') {
                    this.$redirect_reload("/produtosbusca/" + this.textobusca);
                }
                else {
                    this.$mensagem_normal("Digite uma palavra-chave e clique na lupa para pesquisar.");
                }

            },
            CheckLogin() {
                let usuarioTmp = {
                    email: '',
                    senha: '',
                }
                usuarioTmp = JSON.parse(this.$cookie.get(this.$DadosCookie));
                if (usuarioTmp != null) {
                    let _usuario = {
                        email: usuarioTmp.email,
                        senha: usuarioTmp.senha,
                    };
                    this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
                        (response) => {
                            // get body data
                                this.usuario = response.body;
                        },
                        (response2) => {
                            // error callback
                            this.$mensagem_erro(response2.body);
                            
                        }
                    );
                } 
				else{
					this.usuario = '';
				}
            },
			Logout() {
                this.$cookie.delete(this.$DadosCookie);
				localStorage.removeItem(this.$Dadoslocal);
                this.$redirect_reload("/");
            },
        },
        created() {
            this.CheckLogin();
            this.$http
                .get(this.$apiUrl + "/institucional/telas/cabecalho")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaCabecalho = tiu2;
                    if (this.ListaCabecalho != '') {
                        this.Fone1 = this.ListaCabecalho[0].campoExtra1;
                        this.Link1 = this.ListaCabecalho[0].linkBtn1;
                        this.Fone2 = this.ListaCabecalho[0].campoExtra2;
                        this.Link2 = this.ListaCabecalho[0].linkBtn2;
                        this.Fone3 = this.ListaCabecalho[0].campoExtra3;
                    }
                });


            this.$http
                .get(this.$apiUrl + "/SegmentoProdNivel1")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Segmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        if (a.nomeSegmento > b.nomeSegmento) {
                            return 1;
                        }
                        if (a.nomeSegmento < b.nomeSegmento) {
                            return -1;
                        }
                        return 0;
                    });
                });

        },
        mounted() {

        },
        components: {

        },
    };
</script>

<style scoped>
    .desc p {
        font-size: 12px;
        font-weight: 500;
        color: #252525;
        border-right: 1px solid #E5E5E5;
        padding-top: 7px;
        padding-bottom: 7px;
        /* padding-right: 20px; */
        width: 100%;
    }
</style>
